<template>
  <header
    id="top"
    :class="{header: true, isBlog: isBlog || changeMenu, fadeInDown: changeMenu, animated: false, fast: true}"
  >
    <div
      :class="{'btn-toggleMenu': true, 'visible': menuIsVisible}"
      @click="toggleMenu"
    >
      <span />
      <span />
      <span />
    </div>
    <router-link
      :class="['logo', themeColor]"
      :to="{name: 'home'}"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="109.367"
        height="106.694"
        viewBox="0 0 109.367 106.694"
      >
        <g
          id="Group_207"
          transform="translate(-930.131 -140)"
        >
          <g
            id="Group_205"
            transform="translate(179 -112)"
          >
            <g
              id="Group_204"
              transform="translate(157 -168)"
            >
              <path
                id="Subtraction_2"
                d="M53.347,106.694A53.746,53.746,0,0,1,42.6,105.61,53.054,53.054,0,0,1,23.52,97.583,53.51,53.51,0,0,1,4.192,74.112,53.124,53.124,0,0,1,1.084,64.1a53.866,53.866,0,0,1,0-21.5A53.055,53.055,0,0,1,9.111,23.52,53.5,53.5,0,0,1,32.582,4.192,53.073,53.073,0,0,1,42.6,1.084a53.85,53.85,0,0,1,22.234.155,53.109,53.109,0,0,1,20.117,9.123,53.77,53.77,0,0,1,8.107,7.357L72.206,29.773a29.859,29.859,0,0,0-18.859-6.614,30.189,30.189,0,0,0,0,60.377,29.86,29.86,0,0,0,18.859-6.614L93.054,88.974a53.509,53.509,0,0,1-17.6,12.939,53.065,53.065,0,0,1-10.621,3.542A53.686,53.686,0,0,1,53.347,106.694Z"
                transform="translate(594.131 420)"
                fill="#fff"
              />
              <path
                id="arrow"
                d="M38.236,44.275,0,22.138,38.238,0c-.98,1.156-1.869,3.969-2.5,7.922a92.28,92.28,0,0,0-1,14.215,92.277,92.277,0,0,0,1,14.215c.633,3.95,1.522,6.763,2.5,7.921Z"
                transform="translate(665.26 451.209)"
              />
            </g>
          </g>
        </g>
      </svg>
    </router-link>
    <div
      class="social-medias"
    >
      <div
        v-for="(socialMedia, index) in computedSocialMedias"
        :key="index"
      >
        <a
          v-if="socialMedia.name.includes('Phone') || socialMedia.name.includes('Telefone')"
        >
          <img
            :src="socialMedia.src"
            :alt="socialMedia.name"
            :title="socialMedia.name"
          >
        </a>
        <a
          v-else
          :href="socialMedia.url.trim() ? socialMedia.url : ''"
        >
          <img
            :src="socialMedia.src"
            :alt="socialMedia.name"
            :title="socialMedia.name"
          >
        </a>
        <div
          v-if="socialMedia.name.includes('Phone') || socialMedia.name.includes('Telefone')"
          class="social-medias-popup"
        >
          <span>{{ socialMedia.name }}</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapState } from 'vuex';
import defaultSocialMedias from '@/cache/socialMedias';

export default {
    name: 'MainHeader',
    data() {
        return {
            logo: {
                url: require('@/assets/logo-white.png'),
                alt: 'Logotipo Cone',
            },
            changeMenu: false,
            defaultSocialMedias,
        };
    },
    computed: {
        ...mapState(['menuIsVisible', 'animated', 'isBlog', 'themeColor']),
        ...mapState('posts', ['socialMedias']),
        computedSocialMedias() {
            const socialMedias = this.socialMedias.length ? [...this.socialMedias] : this.defaultSocialMedias;
            return socialMedias.map((media) => ({
                name: media.label,
                // eslint-disable-next-line import/no-dynamic-require
                src: this.isURL(media.icon) || require(`@/assets/${media.icon}.svg`),
                url: media.url,
                labelType: media.label_type,
            }));
        },
    },
    created() {
        window.addEventListener('scroll', () => {
            this.handleScroll();
        });
        if (!this.socialMedias.length) {
            this.getSocialMedias();
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', () => {
            this.handleScroll();
        });
    },
    methods: {
        ...mapActions(['changeMenuVisibility', 'changeIsBlog', 'changeAnimated']),
        ...mapActions('posts', ['getSocialMedias']),
        toggleMenu() {
            this.changeMenuVisibility(
                !this.menuIsVisible,
            );
            this.changeAnimated(
                !this.animated,
            );
            if (this.$router.currentRoute.name === 'blog') {
                this.changeIsBlog(!this.isBlog);
            }
        },
        handleScroll() {
            this.changeMenu = document.documentElement.scrollTop >= 300 && this.$router.currentRoute.name !== 'blog';
        },
        isURL(value) {
            try {
                return new URL(value);
            } catch (e) {
                return false;
            }
        },

        removeClick(event) {
            event.currentTarget.removeAttribute('href');
            event.preventDefault();
            event.stopPropagation();
            return false;
        },
    },
};
</script>
