const menu = [
    {
        ID: 94,
        post_author: '1',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-11-23 03:43:51',
        post_content: '',
        post_title: 'Home',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: 'home',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://wp-test.hermit.com.br/?p=94',
        menu_order: 1,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 94,
        menu_item_parent: '0',
        object_id: '94',
        object: 'custom',
        type: 'custom',
        type_label: 'Link personalizado',
        title: 'Home',
        url: '/',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 236,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-09 13:05:04',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '236',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/236/',
        menu_order: 2,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 236,
        menu_item_parent: '0',
        object_id: '235',
        object: 'page',
        type: 'post_type',
        type_label: 'Página',
        url: 'https://admin-cone.coneconsultoria.com.br/a-cone/',
        title: 'A Cone',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 223,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-09 11:44:13',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '223',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/223/',
        menu_order: 3,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 223,
        menu_item_parent: '0',
        object_id: '222',
        object: 'page',
        type: 'post_type',
        type_label: 'Página',
        url: 'https://admin-cone.coneconsultoria.com.br/equipe/',
        title: 'Equipe',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 167,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-11-28 04:10:10',
        post_content: '',
        post_title: 'Como podemos te ajudar?',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: 'como-podemos-te-ajudar',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/?p=167',
        menu_order: 4,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 167,
        menu_item_parent: '0',
        object_id: '167',
        object: 'custom',
        type: 'custom',
        type_label: 'Link personalizado',
        title: 'Como podemos te ajudar?',
        url: '/posts',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 229,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-09 12:27:00',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '229',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/229/',
        menu_order: 5,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 229,
        menu_item_parent: '167',
        object_id: '228',
        object: 'page',
        type: 'post_type',
        type_label: 'Página',
        url: 'https://admin-cone.coneconsultoria.com.br/atuacao-geral/',
        title: 'Atuação Geral',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 242,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-10 15:48:46',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '242',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/?p=242',
        menu_order: 6,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 242,
        menu_item_parent: '167',
        object_id: '190',
        object: 'post',
        type: 'post_type',
        type_label: 'Post',
        url: 'https://admin-cone.coneconsultoria.com.br/bioeletricidade/',
        title: 'BIOELETRICIDADE',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 267,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-11 11:42:16',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '267',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/?p=267',
        menu_order: 7,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 267,
        menu_item_parent: '167',
        object_id: '265',
        object: 'post',
        type: 'post_type',
        type_label: 'Post',
        url: 'https://admin-cone.coneconsultoria.com.br/biometano/',
        title: 'Biometano',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 239,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-10 15:48:46',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '239',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/?p=239',
        menu_order: 8,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 239,
        menu_item_parent: '167',
        object_id: '203',
        object: 'post',
        type: 'post_type',
        type_label: 'Post',
        url: 'https://admin-cone.coneconsultoria.com.br/conexao-eletrica/',
        title: 'Conexão Elétrica',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 241,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-10 15:48:46',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '241',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/?p=241',
        menu_order: 9,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 241,
        menu_item_parent: '167',
        object_id: '192',
        object: 'post',
        type: 'post_type',
        type_label: 'Post',
        url: 'https://admin-cone.coneconsultoria.com.br/gas-natural/',
        title: 'Gás Natural',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 240,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-10 15:48:46',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '240',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/?p=240',
        menu_order: 10,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 240,
        menu_item_parent: '167',
        object_id: '201',
        object: 'post',
        type: 'post_type',
        type_label: 'Post',
        url: 'https://admin-cone.coneconsultoria.com.br/regulatorio/',
        title: 'Regulatório',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 243,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2020-12-10 15:48:46',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '243',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/?p=243',
        menu_order: 11,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 243,
        menu_item_parent: '167',
        object_id: '187',
        object: 'post',
        type: 'post_type',
        type_label: 'Post',
        url: 'https://admin-cone.coneconsultoria.com.br/solar/',
        title: 'Solar',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
    {
        ID: 299,
        post_author: '3',
        post_date: '2024-09-04 23:58:45',
        post_date_gmt: '2024-09-04 23:58:45',
        post_content: ' ',
        post_title: '',
        post_excerpt: '',
        post_status: 'publish',
        comment_status: 'closed',
        ping_status: 'closed',
        post_password: '',
        post_name: '299',
        to_ping: '',
        pinged: '',
        post_modified: '2024-09-04 23:58:45',
        post_modified_gmt: '2024-09-04 23:58:45',
        post_content_filtered: '',
        post_parent: 0,
        guid: 'https://admin-cone.coneconsultoria.com.br/?p=299',
        menu_order: 12,
        post_type: 'nav_menu_item',
        post_mime_type: '',
        comment_count: '0',
        filter: 'raw',
        db_id: 299,
        menu_item_parent: '167',
        object_id: '297',
        object: 'post',
        type: 'post_type',
        type_label: 'Post',
        url: 'https://admin-cone.coneconsultoria.com.br/descarbonizacao/',
        title: 'DESCARBONIZAÇÃO',
        target: '',
        attr_title: '',
        description: '',
        classes: [
            '',
        ],
        xfn: '',
    },
];

export default menu;
